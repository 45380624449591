:host {
  display: block;
}

.sc-order-shipping {
  &__loading {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
}
