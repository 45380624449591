:host {
  display: block;
}

.radio-group {
  border: none;
  padding: 0;
  margin: 0;
  min-width: 0;

  .radio-group__label {
    display: inline-block;
    padding: 0;
    color: var(--sc-input-label-color);
    font-weight: var(--sc-input-label-font-weight);
    text-transform: var(--sc-input-label-text-transform, none);
    letter-spacing: var(--sc-input-label-letter-spacing, 0);
    margin-bottom: var(--sc-input-label-margin);
  }

  &__hidden-input {
    position: absolute;
    opacity: 0;
    padding: 0px;
    margin: 0px;
    pointer-events: none;
  }

  &--is-required .radio-group__label:after {
    content: ' *';
    color: var(--sc-color-danger-500);
  }
}

::slotted(sc-radio:not(:last-of-type)) {
  display: block;
  margin-bottom: var(--sc-spacing-x-small);
}

// .radio-group:not(.radio-group--has-fieldset) {
//   border: none;
//   padding: 0;
//   margin: 0;
//   min-width: 0;
// }
// .radio-group:not(.radio-group--has-fieldset) .radio-group__label {
//   position: absolute;
//   width: 0;
//   height: 0;
//   clip: rect(0 0 0 0);
//   clip-path: inset(50%);
//   overflow: hidden;
//   white-space: nowrap;
// }

.radio-group--is-rtl {
  &.radio-group {
    &,
    .radio-group__label {
      text-align: right;
    }
  }
}
